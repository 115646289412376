import { useMemo } from "react";

import NineGag from "assets/companies/9gag.svg";
import Accenture from "assets/companies/accenture.svg";
import Adweek from "assets/companies/adweek.svg";
import Afterlight from "assets/companies/afterlight.svg";
import Apartner from "assets/companies/apartner.svg";
import Apple from "assets/companies/apple.svg";
import AppPicker from "assets/companies/apppicker.svg";
import Artsy from "assets/companies/artsy.svg";
import Artwired from "assets/companies/artwired.svg";
import AssaAbloy from "assets/companies/assa-abloy.svg";
import BarneysNewYork from "assets/companies/barneys-new-york.svg";
import BerkshireHathaway from "assets/companies/berkshire-hathaway.svg";
import BlueBottleCoffee from "assets/companies/blue-bottle-coffee.svg";
import BostonGlobe from "assets/companies/boston-globe.svg";
import BuildSpec from "assets/companies/buildspec.svg";
import BusinessInsider from "assets/companies/business-insider.svg";
import Buzzfeed from "assets/companies/buzzfeed.svg";
import CaliforniaDmv from "assets/companies/california-dmv.svg";
import Canon from "assets/companies/canon.svg";
import CBS from "assets/companies/cbs.svg";
import Clear from "assets/companies/clear.svg";
import Clinique from "assets/companies/clinique.svg";
import Clutch from "assets/companies/clutch.svg";
import CNET from "assets/companies/cnet.svg";
import CoalitionAmericanDream from "assets/companies/coalition-for-the-american-dream.svg";
import Coinbase from "assets/companies/coinbase.svg";
import Compass from "assets/companies/compass.svg";
import Crunchbase from "assets/companies/crunchbase.svg";
import Dayo from "assets/companies/dayo.svg";
import DCICapital from "assets/companies/dci-capital.svg";
import Dormakaba from "assets/companies/dormakaba.svg";
import Elevatr from "assets/companies/elevatr.svg";
import Emrap from "assets/companies/emrap.svg";
import Entrepreneur from "assets/companies/entrepreneur.svg";
import Example from "assets/companies/example.svg";
import Fennel from "assets/companies/fennel.svg";
import Figs from "assets/companies/figs.svg";
import FixFix from "assets/companies/fixfix.svg";
import Forbes from "assets/companies/forbes.svg";
import Getaway from "assets/companies/getaway.svg";
import Gimlet from "assets/companies/gimlet.svg";
import Google from "assets/companies/google.svg";
import Hagerty from "assets/companies/hagerty.svg";
import Happify from "assets/companies/happify.svg";
import HarvardBusinessSchool from "assets/companies/harvard-business-school.svg";
import HBO from "assets/companies/hbo.svg";
import IBM from "assets/companies/ibm.svg";
import Ikon from "assets/companies/ikon.svg";
import Jackpocket from "assets/companies/jackpocket.svg";
import Keyme from "assets/companies/keyme.svg";
import KissCostmetics from "assets/companies/kiss-cosmetics.svg";
import Lattice from "assets/companies/lattice.svg";
import Makespace from "assets/companies/makespace.svg";
import Meowwolf from "assets/companies/meowwolf.svg";
import MGMAlternate from "assets/companies/mgm-alternate.svg";
import MGM from "assets/companies/mgm.svg";
import Moscase from "assets/companies/moscase.svg";
import MysteryvibeAlternate from "assets/companies/mysteryvibe-alternate.svg";
import Mysteryvibe from "assets/companies/mysteryvibe.svg";
import Namely from "assets/companies/namely.svg";
import NewYorkPost from "assets/companies/new-york-post.svg";
import NewYorkTimes from "assets/companies/new-york-times.svg";
import Numovi from "assets/companies/numovi.svg";
import Omstars from "assets/companies/omstars.svg";
import Pedal from "assets/companies/pedal.svg";
import Pollen from "assets/companies/pollen.svg";
import Public from "assets/companies/public.svg";
import QuizUp from "assets/companies/quizup.svg";
import Richr from "assets/companies/richr.svg";
import Riteaid from "assets/companies/riteaid.svg";
import Rover from "assets/companies/rover.svg";
import Sakara from "assets/companies/sakara.svg";
import Shinesty from "assets/companies/shinesty.svg";
import Smash from "assets/companies/smash.svg";
import StartupStash from "assets/companies/startup-stash.svg";
import Stayntouch from "assets/companies/stayntouch.svg";
import Streamful from "assets/companies/streamful.svg";
import Superhuman from "assets/companies/superhuman.svg";
import TechCrunch from "assets/companies/techcrunch.svg";
import TheManifest from "assets/companies/the-manifest.svg";
import TonyRobbins from "assets/companies/tony-robbins.svg";
import Trace from "assets/companies/trace.svg";
import TrafficCarma from "assets/companies/traffic-carma.svg";
import Uniqlo from "assets/companies/uniqlo.svg";
import UnitedNations from "assets/companies/united-nations.svg";
import USAToday from "assets/companies/usa-today.svg";
import VerizonAlternate from "assets/companies/verizon-alternate.svg";
import Verizon from "assets/companies/verizon.svg";
import Wandr from "assets/companies/wandr.svg";
import WarbyParker from "assets/companies/warby-parker.svg";
import WhiteHouseAlternate from "assets/companies/white-house-alternate.svg";
import WhiteHouse from "assets/companies/white-house.svg";
import WireCutterAlternate from "assets/companies/wirecutter-alternate.svg";
import WireCutter from "assets/companies/wirecutter.svg";
import WSJ from "assets/companies/wsj.svg";
import YouTeam from "assets/companies/youteam.svg";
import Zift from "assets/companies/zift.svg";

interface CompanyProps {
  slug: string;
  className?: string;
}

const CompanyLogo = ({ slug, ...props }: CompanyProps) => {
  const memoCompanyLogo = useMemo(() => {
    switch (slug.toLowerCase()) {
      case "adweek":
        return <Adweek {...props} />;
      case "accenture":
        return <Accenture {...props} />;
      case "afterlight":
        return <Afterlight {...props} />;
      case "apple":
        return <Apple {...props} />;
      case "apartner":
        return <Apartner {...props} />;
      case "apppicker":
        return <AppPicker {...props} />;
      case "artsy":
        return <Artsy {...props} />;
      case "artwired":
        return <Artwired {...props} />;
      case "assa-abloy":
        return <AssaAbloy {...props} />;
      case "barneys-new-york":
        return <BarneysNewYork {...props} />;
      case "berkshire-hathaway":
        return <BerkshireHathaway {...props} />;
      case "blue-bottle-coffee":
        return <BlueBottleCoffee {...props} />;
      case "boston-globe":
        return <BostonGlobe {...props} />;
      case "buildspec":
        return <BuildSpec {...props} />;
      case "business-insider":
        return <BusinessInsider {...props} />;
      case "buzzfeed":
        return <Buzzfeed {...props} />;
      case "california-dmv":
        return <CaliforniaDmv {...props} />;
      case "canon":
        return <Canon {...props} />;
      case "cbs-los-angeles":
        return <CBS {...props} />;
      case "clear":
        return <Clear {...props} />;
      case "clinique":
        return <Clinique {...props} />;
      case "clutch":
        return <Clutch {...props} />;
      case "cnet":
        return <CNET {...props} />;
      case "coalition-for-the-american-dream":
        return <CoalitionAmericanDream {...props} />;
      case "coinbase":
        return <Coinbase {...props} />;
      case "compass":
        return <Compass {...props} />;
      case "crunchbase":
        return <Crunchbase {...props} />;
      case "dayo":
        return <Dayo {...props} />;
      case "dci-capital":
        return <DCICapital {...props} />;
      case "dormakaba":
        return <Dormakaba {...props} />;
      case "elevatr":
        return <Elevatr {...props} />;
      case "emrap":
        return <Emrap {...props} />;
      case "entrepreneur":
        return <Entrepreneur {...props} />;
      case "example":
        return <Example {...props} />;
      case "fennel":
        return <Fennel {...props} />;
      case "figs":
        return <Figs {...props} />;
      case "fixfix":
        return <FixFix {...props} />;
      case "forbes":
        return <Forbes {...props} />;
      case "getaway":
        return <Getaway {...props} />;
      case "gimlet":
        return <Gimlet {...props} />;
      case "google":
        return <Google {...props} />;
      case "hagerty":
        return <Hagerty {...props} />;
      case "happify":
        return <Happify {...props} />;
      case "harvard-business-school":
        return <HarvardBusinessSchool />;
      case "hbo":
        return <HBO {...props} />;
      case "ibm":
        return <IBM {...props} />;
      case "ikon":
        return <Ikon {...props} />;
      case "jackpocket":
        return <Jackpocket {...props} />;
      case "kiss":
        return <KissCostmetics {...props} />;
      case "keyme":
        return <Keyme {...props} />;
      case "lattice":
        return <Lattice {...props} />;
      case "makespace":
        return <Makespace {...props} />;
      case "meowwolf":
        return <Meowwolf {...props} />;
      case "mgm":
        return <MGM {...props} />;
      case "mgm-alternate":
        return <MGMAlternate {...props} />;
      case "moscase":
        return <Moscase {...props} />;
      case "mysteryvibe":
        return <Mysteryvibe {...props} />;
      case "mysteryvibe-alternate":
        return <MysteryvibeAlternate {...props} />;
      case "namely":
        return <Namely {...props} />;
      case "9gag":
        return <NineGag {...props} />;
      case "new-york-post":
        return <NewYorkPost {...props} />;
      case "new-york-times":
        return <NewYorkTimes {...props} />;
      case "numovi":
        return <Numovi {...props} />;
      case "omstars":
        return <Omstars {...props} />;
      case "pedal":
        return <Pedal {...props} />;
      case "pollen":
        return <Pollen {...props} />;
      case "public":
        return <Public {...props} />;
      case "quizup":
        return <QuizUp {...props} />;
      case "richr":
        return <Richr {...props} />;
      case "riteaid":
        return <Riteaid {...props} />;
      case "rover":
        return <Rover {...props} />;
      case "sakara":
        return <Sakara {...props} />;
      case "shinesty":
        return <Shinesty {...props} />;
      case "smash":
        return <Smash {...props} />;
      case "startup-stash":
        return <StartupStash {...props} />;
      case "stayntouch":
        return <Stayntouch {...props} />;
      case "streamful":
        return <Streamful {...props} />;
      case "superhuman":
        return <Superhuman {...props} />;
      case "techcrunch":
        return <TechCrunch {...props} />;
      case "the-manifest":
        return <TheManifest {...props} />;
      case "tony-robbins":
        return <TonyRobbins {...props} />;
      case "trace":
        return <Trace {...props} />;
      case "traffic-carma":
        return <TrafficCarma {...props} />;
      case "united-nations":
        return <UnitedNations {...props} />;
      case "usa-today":
        return <USAToday {...props} />;
      case "uniqlo":
        return <Uniqlo {...props} />;
      case "verizon-alternate":
        return <VerizonAlternate {...props} />;
      case "verizon":
        return <Verizon {...props} />;
      case "wandr":
        return <Wandr {...props} />;
      case "white-house":
        return <WhiteHouse {...props} />;
      case "white-house-alternate":
        return <WhiteHouseAlternate {...props} />;
      case "wirecutter":
        return <WireCutter {...props} />;
      case "wirecutter-alternate":
        return <WireCutterAlternate {...props} />;
      case "warby-parker":
        return <WarbyParker {...props} />;
      case "wsj":
        return <WSJ {...props} />;
      case "youteam":
        return <YouTeam {...props} />;
      case "zift":
        return <Zift {...props} />;
      default:
        return <p className="font-semibold uppercase body-sm">{slug.replace(/-/g, " ")}</p>;
    }
  }, [props, slug]);

  return memoCompanyLogo;
};

export default CompanyLogo;
